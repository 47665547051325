@font-face {
    font-family: "Raleway-regular";
    src: local("Raleway-regular"), url('../../font/Raleway-Regular.ttf') format("truetype");
}

@font-face {
    font-family: "MTCORSVA";
    src: local("MTCORSVA"), url('../../font/MTCORSVA.ttf') format("truetype");
}
a, a:visited, a:hover, a:active {
    color: inherit;
    font-family: "MTCORSVA";
}

p {
    font-family: "MTCORSVA";
    padding: 0px;
    margin: 0px;
}

.Work{
    align-items: center;
}

.Work-icon {
    display: block;
    margin: auto;
    width: 33%;
    fill: brown;
}

.Work-header {
    margin: auto;
    width: 100%;
    fill: brown;
}

.Work-title {
    text-align: center;
}

.Work-timer {
    background-color: #B0D7BC;
    text-align: center;
    padding: 20px;

}

.Work-timer-message {
    margin-top: 20px;
}

.Work-footer {
    background-color: #D8B865;
    padding: 20px;
}

.Work-footer > * {
    font-size: 15px;
}

.Work-bold {
    font-weight: bold;
}

.Work-day {
    font-size: 40px;
}

h1 {
    text-align: center;
}

.Work-Banderole {
    width: 40%;
    height: fit-content;
}

.Work-P1 {
    width: 80%;
    display: flex;
    margin: 0 auto;
}

.Work-Banderolle-text {
    margin-top: auto;
    margin-bottom: auto;
    padding: 20px;
}

.Work-Cards {
    display: flex;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 40px;
}

.Work-Card {
    padding: 20px;
    width: 30%;
}

.Work-Card-title {
    text-align: center;
    padding-bottom: 10px;
}

.Work-Card-img {
    width: 100%;
    margin-bottom: 10px;
}

.Work-Card-text {
    font-size: 20px;
    margin-bottom: 25px;
}

.Work-Card-link {
    text-decoration: none;
    background-color: #B0D7BC;
    border-radius: 5px;
    padding: 10px;
    display: block;
    text-align: center;
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
}


.Work-pet-container {
    display: flex;
    background-color: #B0D7BC;
    text-align: center;
    padding: 20px;
}

.Work-pet {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    padding: 20px;
}

.Work-pet-text {
    padding:10px
}

.Work-pet-img {
    width: 60px;
    height: fit-content;
}

.Work-prog {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.Work-prog-band {
    width: 60%;
    margin: 30px 0;
}

.Work-prog-icon {
    display: flex;
}

.Work-prog-end {
    margin-left: auto;
}

.Work-prog-1 {
    margin-bottom: 50px;
}

.Work-prog1 {
    text-align: center;
}

.Work-bande-2 {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 80%;
}

.Work-lieu-title {
    font-weight: bold;
    font-size: 35px;
    margin: 20px;
}
.Work-lieu-text {
    margin: 0 20px;
    font-size: 20px;
}

.Work-lieu-text-2 {
    margin-bottom: 15px;
}

.Work-lieu-info {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.Work-lieu-carousel {
    width: 70%;
    margin: 40px auto;
}

.Work-form-input {
    width: 100%;
    padding: 10px;
}

.Work-form-div {
    margin: 30px;
}
.Work-bande-3 {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.Work-reservation{
    width: 80%;
    margin-left: 10%;
    margin-top: 70px;
}

.Work-reservation2{
    width: 80%;
    margin-left: 10%;
    margin-top: 70px;
    text-align: center;
}

.Work-reservation-center {
    width: 100%;
    text-align: center;
}

.Work-logi {
    padding:15px
}

.Work-logi-block{
    padding: 15px;
}

.Work-logi-block > * {
    font-family: none;
}

.Work-logi-block > a {
    font-size: 20px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin: 5px 0px;
    margin: 0px
}

.Work-logi-block > p {
    font-size: 15px
}

.Work-logi {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    padding: 20px;
}

@media only screen and (max-width: 704px) {
    .Work-Cards {
        display: block;
    }

    .Work-prog {
        width: 60%;
    }

    .Work-Card {
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }

    .Work-Card-text {
        font-size: 15px;
    }
}