.ProgIcon{
    width: 100px;
}

.ProgIcon-img{
    width: 100px;
}

.ProgIcon-text {
    text-align: center;
    width: 100%;
}

.ProgIcon-descr {
    text-align: center;
    width: 100%;
}